import React from "react";
import MyNavBar from "../components/MyNavBar.tsx";

export default function TestScreen() {
	return (
		<div style={styles.container}>
			<MyNavBar />
			<div style={styles.content}>
				<h1>Test Screen</h1>
			</div>
		</div>
	);
}

const styles = {
	container: {
		width: "100vw",
		height: "100vh",
	},
	content: {
		width: "100%",
		height: "80%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
};
