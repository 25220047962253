import React, { useEffect, useState } from "react";
import colors from "../utils/colors.ts";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import MyNavBar from "../components/MyNavBar.tsx";
import LoginPanel from "../components/LoginPanel.tsx";

interface Category {
	id: string; // veya `number` eğer ID'ler sayısal ise
	name: string;
}

interface Collection {
	id: string; // veya `number` eğer ID'ler sayısal ise
	name: string;
}

export default function UploadCollectionScreen() {
	const [name, setName] = useState("");
	const [parent_collection_id, setParentCollectionId] = useState("0");
	const [category_id, setCategoryId] = useState("");
	const [imageFile, setImageFile] = useState("");
	const [categories, setCategories] = useState<Category[]>([]);
	const [collections, setCollections] = useState<Collection[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isLogin, setIsLogin] = useState(false);

	//const url  = "http://127.0.0.1:2053/api/categories/all"
	const url  = "https://mekanware.com.tr:2053/api"
	// Get Categories
	useEffect(() => {
		axios
			.get(`${url}/categories/all`)
			.then((response) => {
				setCategories(response.data);
			});
		axios
			.get(`${url}/collections/getAllCollections`)
			.then((response) => {
				setCollections(response.data);
			});
	}, []);

	const handleCollectionImageUpload = (event) => {
		const file = event.target.files[0];
		if (file) {
			setImageFile(file); // Dosyayı doğrudan duruma kaydet
		}
	};
	const handleUploadButton = async () => {
		setIsLoading(true);
		const formData = new FormData();
		formData.append("name", name);
		formData.append("parent_collection_id", parent_collection_id);
		formData.append("category_id", category_id);
		formData.append("file", imageFile);

		axios
			.post(
				`${url}/collections/createCollection`,
				formData
			)
			.then((response) => {
				if (response.status === 201) {
					alert("Koleksiyon yüklendi");
					setIsLoading(false);
					setName("");
					setParentCollectionId("0");
					setCategoryId(categories[0]?.id);
					setImageFile("");
				}
			})
			.catch(async (e) => {
				alert("Bir hata oluştu");
				await setTimeout(() => {}, 2000);
				setIsLoading(false);
				setName("");
				setParentCollectionId("0");
				setCategoryId(categories[0]?.id);
				setImageFile("");
			});
	};
	function Loader() {
		return (
			<RotatingLines
				strokeColor="grey"
				strokeWidth="5"
				animationDuration="0.75"
				width="96"
				visible={true}
			/>
		);
	}
	useEffect(() => {
		setCategoryId(categories[0]?.id);
	}, [categories]);
	if (isLoading) {
		return <Loader />;
	} else if (!isLogin) {
		return <LoginPanel changeWhat={setIsLogin} />;
	}else {
		return (
			<div style={{ ...styles.container, flexDirection: "column" }}>
				<MyNavBar />
				<div>
					<h1 style={{ color: colors.MAIN_LIGHT }}>
						Koleksiyon Yukle
					</h1>
				</div>

				<div style={{ ...styles.boxes, flexDirection: "column" }}>
					<label>ISIM:</label>
					<input
						type="text"
						onChange={(e) => {
							setName(e.target.value);
						}}
						style={{ marginBottom: "25px" }}
					/>

					<label>Kategori Sec</label>
					{categories.length > 0 ? (
						<select
							onChange={(e) => {
								setCategoryId(e.target.value);
							}}
							style={{ marginBottom: "25px" }}
						>
							{categories.map((category) => (
								<option key={category.id} value={category.id}>
									{category.name}
								</option>
							))}
						</select>
					) : (
						<p>Kategoriler yükleniyor...</p>
					)}

					<label>Ust Koleksiyon Sec</label>
					<select
						onChange={(e) => {
							setParentCollectionId(e.target.value);
						}}
						style={{ marginBottom: "25px" }}
					>
						<option value="0">Üst Koleksiyon Yok</option>
						{collections.map((collection: any) => {
							return (
								<option
									key={collection.id}
									value={collection.id}
								>
									{collection.name}
								</option>
							);
						})}
					</select>
					<label>Koleksiyon Fotografini Yukle</label>
					<input
						type="file"
						onChange={handleCollectionImageUpload}
						style={{ marginBottom: "25px" }}
					/>
					<button
						style={{
							backgroundColor: colors.MAIN_DARK,
							color: colors.MAIN_LIGHT,
							padding: "10px 20px",
							borderRadius: "5px",
						}}
						onClick={handleUploadButton}
					>
						Yükle
					</button>
				</div>
			</div>
		);
	}
}

const styles = {
	container: {
		display: "flex",
		height: "100vh",
		width: "100%",
		marginTop: "30px",
		alignItems: "center",
		justifyContent: "flex-start",
		backgroundColor: colors.MAIN_DARK,
	},
	boxes: {
		display: "flex",
		alignItems: "center",
		marginHorizontal: "auto",
		backgroundColor: colors.MAIN_LIGHT,
		width: "70%",
		height: "80%",
		borderRadius: "10px",
	},
};
