import React, { useEffect, useState } from "react";
import colors from "../utils/colors.ts";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import MyNavBar from "../components/MyNavBar.tsx";
import LoginPanel from "../components/LoginPanel.tsx";

export default function UploadSoundScreen() {
	const [name, setName] = useState("");
	const [isPremium, setIsPremium] = useState("false");
	const [categoryId, setCategoryId] = useState("");
	const [collectionId, setCollectionId] = useState("");
	const [soundFile, setSoundFile] = useState("");
	const [categories, setCategories] = useState([]);
	const [collections, setCollections] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [isLogin, setIsLogin] = useState(false);

	const url = "https://mekanware.com.tr:2053/api";
	// Get Categories
	useEffect(() => {
		axios.get(`${url}/categories/all`).then((response) => {
			setCategories(response.data);
		});
		axios.get(`${url}/collections/getAllCollections`).then((response) => {
			setCollections(response.data);
		});
	}, []);

	const handleSoundFileUpload = (event) => {
		const file = event.target.files[0];
		if (file) {
			setSoundFile(file); // Dosyayı doğrudan duruma kaydet
		}
	};
	const handleUploadButton = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		const formData = new FormData();
		formData.append("name", name);
		formData.append("is_premium", isPremium);
		formData.append("category_id", categoryId);
		formData.append("collection_id", collectionId);
		formData.append("file", soundFile);

		await axios
			.post(`${url}/sounds/uploadSound`, formData)
			.then((response) => {
				//console.log(response.status);
				if (response.status === 201) {
					alert("Ses yüklendi");
					setIsLoading(false);
					setName("");
					setIsPremium("false");
					setCategoryId("");
					setCollectionId("");
					setSoundFile("");
				}
			})
			.catch(async (e) => {
				alert("Bir hata oluştu");
				await setTimeout(() => {}, 2000);
				setIsLoading(false);
				setName("");
				setIsPremium("false");
				setCategoryId("");
				setCollectionId("");
				setSoundFile("");
			});
	};
	function Loader() {
		return (
			<RotatingLines
				strokeColor="grey"
				strokeWidth="5"
				animationDuration="0.75"
				width="96"
				visible={true}
			/>
		);
	}
	useEffect(() => {
		//console.log(collectionId);
	}, [collectionId]);

	if (isLoading) {
		return <Loader />;
	} else if (!isLogin) {
		return <LoginPanel changeWhat={setIsLogin} />;
	} else {
		return (
			<div style={{ ...styles.container, flexDirection: "column" }}>
				<MyNavBar />
				<div>
					<h1 style={{ color: colors.MAIN_LIGHT }}>Ses Yukle</h1>
				</div>

				<div style={{ ...styles.boxes, flexDirection: "column" }}>
					<label>ISIM:</label>
					<input
						type="text"
						onChange={(e) => {
							setName(e.target.value);
						}}
						style={{ marginBottom: "25px" }}
					/>
					<label>Premium mu ?</label>
					<input
						type="checkbox"
						onChange={(e) => {
							setIsPremium(e.target.checked.toString());
						}}
						style={{ marginBottom: "25px" }}
					/>

					<label>Kategori Sec</label>
					<select
						onChange={(e) => {
							setCategoryId(e.target.value);
						}}
						style={{ marginBottom: "25px" }}
					>
						<option value="0">Kategori Sec</option>
						{categories.map((category: any) => {
							return (
								<option key={category.id} value={category.id}>
									{category.name}
								</option>
							);
						})}
					</select>
					<label>Koleksiyon Sec</label>
					<select
						onChange={(e) => {
							setCollectionId(e.target.value);
						}}
						style={{ marginBottom: "25px" }}
					>
						<option value="0">Koleksiyon Sec</option>
						{collections.map((collection: any) => {
							return (
								<option
									key={collection.id}
									value={collection.id}
								>
									{collection.name}
								</option>
							);
						})}
					</select>
					<label>Sesi Yukle</label>
					<input
						type="file"
						onChange={handleSoundFileUpload}
						style={{ marginBottom: "25px" }}
					/>
					<button
						style={{
							backgroundColor: colors.MAIN_DARK,
							color: colors.MAIN_LIGHT,
							padding: "10px 20px",
							borderRadius: "5px",
						}}
						onClick={handleUploadButton}
					>
						Yükle
					</button>
				</div>
			</div>
		);
	}
}

const styles = {
	container: {
		display: "flex",
		height: "100vh",
		width: "100%",
		marginTop: "30px",
		alignItems: "center",
		justifyContent: "flex-start",
		backgroundColor: colors.MAIN_DARK,
	},
	boxes: {
		display: "flex",
		alignItems: "center",
		marginHorizontal: "auto",
		backgroundColor: colors.MAIN_LIGHT,
		width: "70%",
		height: "80%",
		borderRadius: "10px",
	},
};
