import React from "react";

export default function HomeScreen() {
	return (
		<div style={styles.container}>
			{" "}
			<iframe
				src="https://bio.link/mekanware"
				width="100%"
				height="100%"
				title="Mekanware"
				style={{ border: "none" }}
			/>
		</div>
	);
}

const styles = {
	container: {
		width: "100%",
		height: "100%",
	},
};
