const PRIMARY = '#443333';
const SECONDARY = '#EEA849';
const CONTRAST = '#FFFFFF';
const INACTIVE_CONTRAST = 'rgba(255, 255, 255, 0.5)';
const ERROR = '#FF5B14';
const SUCCESS = '#3BEF1E';
const OVERLAY = 'rgba(45, 35, 35, 0.5)';


const MAIN_DARK = "#637A9F"
const MAIN_LIGHT= "#C9D7DD"
const SECOND_DARK = "#E8C872"
const SECOND_LIGHT = "#FFF3CF"
const NEW_PRIMARY = "#F9EFDB"

const colors = {
  PRIMARY,
  SECONDARY,
  CONTRAST,
  INACTIVE_CONTRAST,
  ERROR,
  SUCCESS,
  OVERLAY,
  SECOND_DARK,
  SECOND_LIGHT,
  MAIN_LIGHT,
  MAIN_DARK,
  NEW_PRIMARY,

};

export default colors;
