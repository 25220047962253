import React from "react";
import AdminMainScreen from "./screens/AdminMainScreen.tsx";
import HomeScreen from "./screens/HomeScreen.tsx";
import MyNavBar from "./components/MyNavBar.tsx";
import colors from "./utils/colors.ts";
import UploadSoundScreen from "./screens/UploadSoundScreen.tsx";
import { Routes, Route } from "react-router-dom";
import UploadCollectionScreen from "./screens/UploadCollectionScreen.tsx";
import TestScreen from "./screens/TestScreen.tsx";

function App() {
	return (
		<div style={{ ...styles.container, flexDirection: "column" }}>
			<Routes>
				<Route path="/" element={<HomeScreen />} />
				<Route path="/admin/uploadSound" element={<UploadSoundScreen />} />
				<Route
					path="/admin/uploadCollection"
					element={<UploadCollectionScreen />}
				/>
				<Route path="/admin/test" element={<TestScreen />} />
			</Routes>
		</div>
	);
}
const styles = {
	container: {
		display: "flex",
		alignItems: "center",
		backgroundColor: colors.MAIN_DARK,
		height: "100vh",
	},
};
export default App;
