import React from "react";
import colors from "../utils/colors.ts";
import { Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function MyNavBar(props) {
	const navigate = useNavigate();

	return (
		<div
			style={{ ...styles.container, position: undefined }}
			className="w-[100%] h-[75px] "
		>
			<div style={styles.navbarArea}>
				<button
					style={styles.button}
					onClick={() => {
						navigate("/");
					}}
				>
					Anasayfa
				</button>
				<button
					style={styles.button}
					onClick={() => {
						navigate("/admin/uploadSound");
					}}
				>
					Ses Yukle
				</button>
				<button
					style={styles.button}
					onClick={() => {
						navigate("/admin/uploadCollection");
					}}
				>
					Koleksiyon Yukle
				</button>
				<button
					style={styles.button}
					onClick={() => {
						navigate("/admin/test");
					}}
				>
					Test Et
				</button>
			</div>
		</div>
	);
}

const styles = {
	container: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: "30px",
		position: "fixed",
		backgroundColor: colors.MAIN_DARK,
	},
	navbarArea: {
		display: "flex",
		height: "100%",
		width: "80%",
		backgroundColor: "blue",
		borderRadius: "15px",
		alignItems: "center",
		justifyContent: "space-around",
	},
	button: {
		backgroundColor: "black",
		color: "white",
		padding: "10px",
		borderRadius: "15px",
	},
};
