import React, { useState } from "react";
import colors from "../utils/colors.ts";

export default function LoginPanel(props) {
	const { changeWhat } = props;
	const [usernameInput, setUsernameInput] = useState("");
	const [passwordInput, setPasswordInput] = useState("");

	const handleClick = () => {
		if (usernameInput === "admin" && passwordInput === "x") {
			changeWhat(true);
		}
	};
	return (
		<div style={styles.container}>
			<div style={styles.panelBox}>
				<input
					style={styles.input}
					type="text"
					placeholder="Username"
					value={usernameInput}
					onChange={(e) => setUsernameInput(e.target.value)}
				/>
				<input
					style={styles.input}
					type="text"
					placeholder="Password"
					value={passwordInput}
					onChange={(e) => setPasswordInput(e.target.value)}
				/>
				<input
					style={{
						...styles.input,
						backgroundColor: colors.NEW_PRIMARY,
					}}
					type="button"
					value="Giris Yap"
					onClick={handleClick}
				/>
			</div>
		</div>
	);
}

const styles = {
	container: {
		height: "100%",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	panelBox: {
		height: "600px",
		width: "600px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column" as "column",
		backgroundColor: colors.SECOND_DARK,
	},
	input: {
		height: "40px",
		width: "200px",
		margin: "10px",
		border: "none",
		borderRadius: "10px",
		padding: "10px",
	},
};
